import React, { useCallback, useState, useEffect } from 'react';
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import Web3 from 'web3';
import './App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AnimatedNumber from 'react-animated-number';
import OnRamp from "./OnRamp.js"
import Login from "./Login.js"
import {ethers} from 'ethers';
import { BigNumber } from 'ethers';

const sdk = new CoinbaseWalletSDK({
  appName: 'Crypto Fund',
  appLogoUrl: 'https://i.ibb.co/YPyXgTS/Frame-54-1.png',
  chainIds: [8453]
});

const endpoint = "https://cryptofund-d321fef37708.herokuapp.com"

const DEFAULT_ETH_JSONRPC_URL = 'https://few-holy-cloud.base-mainnet.quiknode.pro/540aed7fe1e954f46b2f8c28e723a35fa0c83b98';
const DEFAULT_CHAIN_ID = 8453;
const ethereum = sdk.makeWeb3Provider();
const web3 = new Web3(ethereum);

const contractABI = [
  {
    "constant": false,
    "inputs": [
      {
        "name": "usdAmount",
        "type": "uint256"
      }
    ],
    "name": "redeemusd",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
;
const contractAddress = '0x596D0a27C8dF154b50acD419AF4eFf60409a2592';

const contract = new web3.eth.Contract(contractABI, contractAddress);

export default function App() {
  const [address, setAddress] = useState(null);
  const [balance, setBalance] = useState(0.00);
  const [profit, setProfit] = useState(0);
  const [refreshBalance, setRefreshBalance] = useState(0);
  const [open, setOpen] = useState(false);
  const [redeem, setRedeem] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);


  const handleSuccess = async (address) => {
    console.log('address', address);
    setAddress(address);
  }

  useEffect(() => {
    const fetchbal = async () => {
      if (address) {
        const bal = await fetch("https://cryptofund-d321fef37708.herokuapp.com/fundTokenUsdValue/" + address);
        const balance = await bal.json();
        const usdvalue = balance?.usdValue || 0;
        console.log("BALANCE: ", usdvalue)
        setBalance(usdvalue);

        const prof = await fetch(endpoint + "/profitOrLoss/" + address);
        const profit = await prof.json();
        const profitValue = profit?.profitOrLoss || 0;
        console.log("PROFIT: ", profitValue)
        setProfit(profitValue);
      }
    }
    fetchbal();
  }, [address, refreshBalance]);

  const redeemFunds = async () => {
    if (!address) return;
    const usdAmount = redeem
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const fromAddress = accounts[0];
      console.log("User inputted USD: " + usdAmount)
  
      // Validate and convert the usdAmount
      if (isNaN(usdAmount) || usdAmount <= 0) {
        throw new Error('Invalid USD amount');
      }
  
      // Convert the usdAmount to the appropriate format with 6 decimals
      const usdAmountInWei = BigNumber.from(ethers.utils.parseUnits(usdAmount.toString(), 6));
      console.log('usdAmountInWei:', usdAmountInWei.toString());
  
      // Estimate gas for the redeem function
      const gas = await contract.methods.redeemusd(usdAmountInWei.toString()).estimateGas({ from: fromAddress });
      console.log('Gas estimate:', gas);
  
      // Get the current gas price
      const gasPrice = await web3.eth.getGasPrice();
      console.log('Current gas price:', gasPrice);
      console.log("Final USD in wei submitted to trx: ", usdAmountInWei.toString())
      // Call the redeem function with the converted amount, gas, and gas price
      await contract.methods.redeemusd(usdAmountInWei.toString()).send({
        from: fromAddress,
        gas: gas,
        gasPrice: gasPrice
      });
  
      setRefreshBalance(prev => prev + 1);  // Refresh the balance after redemption
      handleClose();
    } catch (error) {
      console.error('Error redeeming funds:', error);
      if (error.data) {
        console.error('Error data:', error.data);
      }
    }
  };


  return (
    <div className="AppDesktop">
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiModal-backdrop': {
              backdropFilter: 'blur(5px)',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <div className="modalCard">
        <div className="modalTopside">Funds successfully sent!</div>
        <div className="modalBottomside">
          <span>It will take around 30 seconds for them to arrive. You can close this modal, and click the <b>Check Funds</b> button after a while, and the balance will update.</span>
        </div>
        </div>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiModal-backdrop': {
              backdropFilter: 'blur(5px)',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <div className="modalCard">
          <div className="modalTopside">Cash out</div>
          <div className="modalBottomside">
            <span>You have <b>{balance.toFixed(2)}</b> USD worth of fund tokens in your account.</span>
            <div sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <FormControl fullWidth sx={{ width: "100%", marginTop: "15px" }}>
                <InputLabel
                  htmlFor="outlined-adornment-amount"
                  sx={{
                    color: '#000000',
                    '&.Mui-focused': {
                      color: '#000000',
                    }
                  }}
                >
                  Amount
                </InputLabel>
                <OutlinedInput
                  label="Tokens"
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  endAdornment={<InputAdornment position="end">
                    <div onClick={() => redeemFunds(balance)} className="cashOutButton">Cash out</div>
                  </InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(e) => setRedeem(e.target.value)}
                  fullWidth
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  sx={{
                    width: '100%',
                    paddingRight: "4px",
                    boxSizing: "border-box",
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E0E3E7',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E0E3E7',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E0E3E7',
                    }
                  }}
                />
              </FormControl>
              <div className="cashoutAvailable">Available: {balance.toFixed(2)} USD</div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="App">
        {address ?
          <>
            <div className="landing">
              <div className="topSide opacityanimate">
                <div className="yourBalance">Your balance</div>
                <div className="animNum">
                  <AnimatedNumber component="text" value={balance}
                    style={{
                      transition: '0.8s ease-out',
                      fontSize: "72px",
                      fontWeight: 700,
                      cursor: "pointer"
                    }}
                    onClick={() => setRefreshBalance(prev => prev + 1)}
                    duration={700}
                    formatValue={n => "$" + n.toFixed(2)} />
                </div>
                {balance > 0 ?
                  <div className="balanceExplainer">
                    <div className="profit">
                      <AnimatedNumber component="text" value={profit}
                        style={{
                          transition: '0.8s ease-out',
                        }}
                        duration={700}
                        formatValue={n => n.toFixed(2) + " USD"} />
                    </div> {profit.toFixed(2) >= 0 ? "profit." : "loss."}
                  </div>
                  :
                  <div className="balanceExplainer">Tap to refresh.</div>
                }
              </div>
              <div className="bottomSide">
                <OnRamp handleOpen2={handleOpen2} destinationWalletAddress={"0x596D0a27C8dF154b50acD419AF4eFf60409a2592"} address={address} setRefreshBalance={setRefreshBalance} endpoint={endpoint} />
                <div className="seePerformanceButton" onClick={handleOpen}>Cash out</div>
              </div>
            </div>
          </>
          :
          <div className="landing">
            <div className="topSide opacityanimate">
              <div className="landingBrand"><img src="logo.svg" width="24px" height="24px" /> <div>Crypto Fund</div></div>
              <div className="landingPerformance"><b>100 USD</b> invested year ago now worth <b>250 USD</b></div>
              <Login handleSuccess={handleSuccess} />
              <div className="getStartedButtonExplainer">Investing takes 3 minutes.</div>
            </div>
            <div className="bottomSideLanding">
              <div className="landingListItem" style={{ animationDelay: "0s" }}><img className="landingTick" src="tick.svg" width="18px" height="18px" /> Secure</div>
              <div className="landingListItem" style={{ animationDelay: "0.2s" }}><img className="landingTick" src="tick.svg" width="18px" height="18px" /> Professional portfolio managers</div>
              <div className="landingListItem" style={{ animationDelay: "0.4s" }}><img className="landingTick" src="tick.svg" width="18px" height="18px" /> High growth</div>
              <div className="landingListItem" style={{ animationDelay: "0.6s" }}><img className="landingTick" src="tick.svg" width="18px" height="18px" /> 0% fees</div>
            </div>
          </div>
        }
        <div className="socials">
          <a href="https://warpcast.com/cryptofundglobal" target="_blank"><img className="socialimage scaleanimate" style={{ animationDelay: "1.4s" }} src="farcaster.png" width="30px" height="30px" /></a>
        </div>
      </div>
    </div>
  );
}

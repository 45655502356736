

import React, { useEffect, useState, useRef } from 'react';
import { generateOnRampURL } from '@coinbase/cbpay-js';

function CoinbaseButton({handleOpen2, destinationWalletAddress, address, setRefreshBalance, endpoint}) {
  const [onRampURL, setOnRampURL] = useState('');
  const iframeRef = useRef(null);

  const refetchinvest = async () => {
    const response = await fetch("https://cryptofund-d321fef37708.herokuapp.com/transactions", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ethAddress: address
      }),
    })
    const data = await response.json()
    console.log("Refetching balance in 5 seconds.")
    setTimeout(() => {
      setRefreshBalance(prev => prev + 1)
    }, 5000)
  }

  useEffect(() => {
    const options = {
      appId: 'b2f0a721-6737-4735-bb10-7827098e8794', // Replace with your actual App ID
      destinationWallets: [
        { address: "0x596D0a27C8dF154b50acD419AF4eFf60409a2592", supportedNetworks:["base"], assets:['ETH']}
      ],
      partnerUserId: address,
      handlingRequestedUrls: true,
      experienceLoggedIn: 'embedded',
      experienceLoggedOut: 'embedded',
    };

    const url = generateOnRampURL(options);
    setOnRampURL(url);
  }, [destinationWalletAddress]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'https://pay.coinbase.com') return;

      try {
        const { data } = JSON.parse(event.data);
        if (data.eventName === 'onRampSuccess') {
          console.log('Onramp successfu!');
        } else if (data.eventName === 'onRampExit') {
          console.log('User exited the flow.');
        }
        else if (data.eventName === 'success') {
          handleOpen2()

          console.log('Transaction success state. Calling the api transactions endpoint with the address:', address);
          setTimeout(() => {
            fetch("https://cryptofund-d321fef37708.herokuapp.com/transactions", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                ethAddress: address
              }),
            })
          }, 12000)

            setTimeout(() => {
              setRefreshBalance(prev => prev + 1)
            }, 10000)

          
        } else {
          console.log('Event:', data);
        }
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleOnClick = () => {
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const newWindow = window.open(
      onRampURL,
      'CoinbaseOnrampPopup',
      `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars=yes,status=1`
    );

    newWindow.onload = () => {
      newWindow.postMessage('start', '*');
    };
  };

  return (
    <div>
      <div onClick={handleOnClick} style={{ cursor: 'pointer' }} className="generalButton">
        Add funds
      </div>
      <div onClick={() => refetchinvest()} style={{ cursor: 'pointer', marginTop:"20px" }} className="generalButton">
        Check funds
      </div>
      <iframe
        ref={iframeRef}
        src={onRampURL}
        style={{ display: 'none' }}
        title="Coinbase Onramp"
      />
      {!onRampURL && <p>Loading...</p>}
    </div>
  );
}

export default CoinbaseButton;
